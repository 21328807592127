import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export default {
    install(Vue, options = {}) {
        if (options.locale) {
            dayjs.locale(require("dayjs/locale/" + options.locale + ".js"))
            dayjs.tz.setDefault(dayjs.tz.guess())
        }

        Vue.prototype.$dayjs = dayjs

        Vue.filter("dayjs", (value, method, ...params) => {
            const d = dayjs(value)
            if (!d.isValid()) return ""

            return d[method].apply(d, params)
        })
    }
}
