import utils from "@/utils"
const RequestsListPage = () => import("../views/support/RequestsList.vue")
const ReplyTemplatesPage = () => import("../views/support/ReplyTemplates.vue")
const hideOnTest = utils.isEnvTest()

const route = [
    {
        path: "/support/templates",
        name: "Gestione risposte supporto",
        component: ReplyTemplatesPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Supporto / Risposte",
            tags: ["emails", "lista", "answers"]
        }
    }
]

if (!hideOnTest) {
    route.push({
        path: "/support/requests",
        name: "Richieste",
        component: RequestsListPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Supporto / Richieste",
            tags: ["emails", "lista", "client"]
        }
    })
}

export default route
