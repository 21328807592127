import { Http } from "vue-resource"
import utils from "../utils"

export default {
    getStats() {
        return Http.get(utils.getUrl("common/stats"))
    },
    getReportsStats() {
        return Http.get(utils.getUrl("common/reports-stats"))
    },
    getChatStats() {
        return Http.get(utils.getUrl("common/chat-stats"))
    },
    getActivityLogs({ pageNumber, pageItems, filters }) {
        return Http.get(utils.getUrl("common/activity-logs"), {
            params: {
                pageNumber,
                pageItems,
                ...filters
            }
        })
    },
    getActivityLogsFilters() {
        return Http.get(utils.getUrl("common/activity-logs-filters"))
    },
    getErrorLogs({ pageNumber, pageItems, filters }) {
        return Http.get(utils.getUrl("common/error-logs"), {
            params: {
                pageNumber,
                pageItems,
                ...filters
            }
        })
    },
    getErrorLogsFilters(server) {
        return Http.get(utils.getUrl("common/error-logs-filters"), {
            params: {
                server
            }
        })
    },

    getThreadLabels() {
        return Http.get(utils.getUrl(`common/configurations/thread-labels`))
    },
    addThreadLabel(name, color) {
        return Http.put(utils.getUrl(`common/configurations/thread-labels`), { name, color })
    },
    delThreadLabel(name) {
        return Http.patch(utils.getUrl(`common/configurations/thread-labels`), { name })
    },

    getSupportSign() {
        return Http.get(utils.getUrl(`common/configurations/support-sign`))
    },
    updateSupportSign(template) {
        return Http.post(utils.getUrl(`common/configurations/support-sign`), { template })
    },

    getUrlMeta(url) {
        return Http.get(utils.getUrl(`common/url-meta/${encodeURIComponent(url)}`))
    },

    getChannelMessageCron() {
        return Http.get(utils.getUrl(`common/channel-message-cron`))
    },
    /**
     * @typedef HttpResponse
     *
     * @typedef Payload
     * @property {string} text message text
     * @property {string} image message image
     * @property {Object} buttons message buttons
     * @property {string} datetime datetime message
     *
     * @param {Payload} payload message
     *
     * @return {Promise<HttpResponse>}
     */
    addChannelMessageCron(datetime, { text, image, buttons }) {
        return Http.post(utils.getUrl(`common/channel-message-cron`), { datetime, text, image, buttons })
    },
    deleteChannelMessageCron(id) {
        return Http.delete(utils.getUrl(`common/channel-message-cron/${id}`))
    },

    getChannelMessageTemplates() {
        return Http.get(utils.getUrl(`common/channel-message-template`))
    },
    addChannelMessageTemplates(template) {
        return Http.post(utils.getUrl(`common/channel-message-template`), template)
    },
    updateChannelMessageTemplates(template, id) {
        return Http.put(utils.getUrl(`common/channel-message-template/${id}`), template)
    },
    deleteChannelMessageTemplates(id) {
        return Http.delete(utils.getUrl(`common/channel-message-template/${id}`))
    },

    getPenaltyReasons() {
        return Http.get(utils.getUrl(`common/penalty-reasons`))
    },
    export(filters) {
        return Http.post(utils.getUrl("common/data-export"), filters)
    },
    getExportList() {
        return Http.get(utils.getUrl("common/data-export-list"))
    }
}
