import { Http } from "vue-resource"
import utils from "../utils"

export default {
    deleteMessage(messageId, roomId) {
        return Http.post(utils.getUrl(`chat/delete-message`), { messageId, roomId })
    },
    reportMessage(messageId, roomId) {
        return Http.post(utils.getUrl(`chat/report-message`), { messageId, roomId })
    },
    getStopWords() {
        return Http.get(utils.getUrl(`chat/stop-words`))
    },
    addStopWord(word) {
        return Http.put(utils.getUrl(`chat/stop-words`), { word })
    },
    removeStopWord(word) {
        return Http.patch(utils.getUrl(`chat/stop-words`), { word })
    },
    getStickers() {
        return Http.get(utils.getUrl(`chat/stickers`))
    },
    getRooms(id) {
        return Http.get(utils.getUrl(`chat/rooms${id ? "/" + id : ""}`))
    },
    getRoomAttachments({ roomId, messagesPerPage = 20, page = 1 }) {
        return Http.get(utils.getUrl(`chat/rooms/${roomId}/attachments`), {
            params: {
                messagesPerPage,
                page
            }
        })
    },
    getReports() {
        return Http.get(utils.getUrl(`chat/reports`))
    },
    getReportsById(id) {
        return Http.get(utils.getUrl(`chat/reports/${id}`))
    },
    reportPin(id) {
        return Http.put(utils.getUrl(`chat/report/pin`), { id })
    },
    reportUnpin(id) {
        return Http.put(utils.getUrl(`chat/report/unpin`), { id })
    },
    /**
     *
     * @param {String[]} reportsId reports id list
     */
    removeReport(reportsId) {
        const payload = typeof reportsId === "string" ? [reportsId] : reportsId
        return Http.post(utils.getUrl(`chat/report/delete`), payload)
    },
    /**
     *
     * @param {Object[]} rooms rooms selected
     * @param {String} rooms[].id room id
     * @param {String} rooms[].name room name
     * @param {String} rooms[].type room type
     * @param {Number} rooms[].online room users online
     * @param {string} text
     * @param {string} image
     */
    saveInstitutionalMessage(rooms, text, image, url) {
        return Http.post(utils.getUrl(`chat/message/institutional`), { rooms, text, image, url })
    },
    getInstitutionalMessages() {
        return Http.get(utils.getUrl(`chat/message/institutional`))
    },
    getStatsYears() {
        return Http.get(utils.getUrl(`chat/stats/years`))
    },
    /**
     * @typedef ChatStat
     * @property {string} _id id
     * @property {string} created_at date
     * @property {ChatStatData} data stat data
     *
     * @typedef ChatStatData
     * @property {number} images images count
     * @property {number} messages messages count
     * @property {number} total total online users
     * @property {ChatStatDataRoom[]} rooms rooms data
     *
     * @typedef ChatStatDataRoom
     * @property {string} _id id
     * @property {string} name name
     * @property {string} roomType roomType
     * @property {number} online online
     * @property {number} images images
     * @property {number} messages messages
     * @property {number} msg_users users messages distinct
     * @property {number} img_users users images distinct
     *
     * @param {string} year year
     *
     * @return {Promise<ChatStat>}
     */
    getStatsByYear(year) {
        return Http.get(utils.getUrl(`chat/stats/${year}`), { params: { roomsData: true } })
    }
}
