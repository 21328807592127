import { Http } from "vue-resource"
import utils from "../utils"

export default {
    getAll({ pagination, page, filters }) {
        return Http.get(utils.getUrl("users"), {
            params: {
                pagination,
                page,
                ...filters
            }
        })
    },
    getInfo(id) {
        return Http.get(utils.getUrl("users") + `/${id}`)
    },
    getCloud(id) {
        return Http.get(utils.getUrl("users") + `/${id}/cloud`)
    },
    getChildren(id) {
        return Http.get(utils.getUrl("users") + `/${id}/children/cloud`)
    },
    getPdf(id) {
        return Http.get(utils.getUrl("users") + `/${id}/pdf`, { responseType: "arraybuffer" })
    },
    getForgotPasswordLink(id) {
        return Http.get(utils.getUrl("users") + `/${id}/forgot-password/link`)
    },
    sendForgotPasswordEmail(id) {
        return Http.get(utils.getUrl("users") + `/${id}/forgot-password`)
    },
    ban(id, reason) {
        return Http.post(utils.getUrl("users") + `/${id}/ban`, { reason })
    },
    unban(id, reason) {
        return Http.post(utils.getUrl("users") + `/${id}/unban`, { reason })
    },
    mute(id, reason, duration) {
        return Http.post(utils.getUrl("users") + `/${id}/mute`, { reason, duration })
    },
    unmute(id, reason) {
        return Http.post(utils.getUrl("users") + `/${id}/unmute`, { reason })
    },
    delete(id) {
        return Http.delete(utils.getUrl("users") + `/${id}`)
    },
    update(id, params) {
        return Http.put(utils.getUrl("users") + `/${id}`, params)
    }
}
