import { Http } from "vue-resource"
import utils from "../utils"

export default {
    get({ itemsPerPage = 50, lastId = "", notSeen = false }) {
        return Http.get(utils.getUrl(`emails/inbox`), {
            params: {
                itemsPerPage,
                lastId,
                notSeen
            }
        })
    },
    getCount() {
        return Http.get(utils.getUrl(`emails/inbox/count`))
    },
    getNotSeenCount() {
        return Http.get(utils.getUrl(`emails/not-seen/count`))
    },
    getThread(id) {
        return Http.get(utils.getUrl(`emails/inbox/${id}`))
    },
    addThreadLabel(id, label) {
        return Http.put(utils.getUrl(`emails/inbox/${id}/thread-label`), { label })
    },
    delThreadLabel(id, name) {
        return Http.patch(utils.getUrl(`emails/inbox/${id}/thread-label`), { name })
    },
    setSeen(id) {
        return Http.patch(utils.getUrl(`emails/inbox/${id}/set-seen`))
    },
    setNotSeen(id) {
        return Http.patch(utils.getUrl(`emails/inbox/${id}/set-not-seen`))
    },

    /**
     *
     * @param {Object} email email to send
     * @param {String} email.to email address
     * @param {String} email.inReplyTo reply message id
     * @param {String} email.subject email subject
     * @param {String} email.text email plain text
     * @param {String} email.html email html
     * @param {Object[]} email.attachments[] attachments list
     * @param {String} email.attachments[].filename attachment filename
     * @param {String} email.attachments[].path attachment content ex: "data:image/jpeg;base64,/9j/4AAQSkZ..."
     * @param {String} email.attachments[].content attachment content ex: "data:image/jpeg;base64,/9j/4AAQSkZ..."
     * @param {String} email.attachments[].contentType attachment contentType ex: "image/jpeg"
     * @param {String} email.attachments[].encoding attachment encoding ex: "base64"
     */
    send(email) {
        return Http.post(utils.getUrl(`emails/inbox`), email)
    },

    /**
     *
     * @param {Object} reply reply to send
     * @param {String} reply.id thread id
     * @param {String} reply.message reply message
     * @param {String} reply.attachment reply attachment
     */
    sendTicketReply(reply) {
        return Http.post(utils.getUrl(`emails/thread/reply`), reply)
    },

    getTemplates() {
        return Http.get(utils.getUrl(`emails/templates`))
    },
    addTemplate(template) {
        return Http.post(utils.getUrl(`emails/templates`), template)
    },
    updateTemplate(template, id) {
        return Http.put(utils.getUrl(`emails/templates/${id}`), template)
    },
    deleteTemplate(id) {
        return Http.delete(utils.getUrl(`emails/templates/${id}`))
    }
}
