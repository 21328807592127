import { Http } from "vue-resource"
import utils from "../utils"

export default {
    login({ email, password, otp, token }) {
        return Http.post(utils.getUrl("login"), { email, password, otp, token })
    },
    logout() {
        return Http.get(utils.getUrl("logout"))
    },
    checkSession() {
        return Http.get(utils.getUrl("check-session"))
    }
}
