<template>
    <div id="app">
        <transition name="fade-zoom">
            <div class="splash-screen" v-if="splashScreen">
                <div class="wrap">
                    <img src="/logo_anim.svg" loading="lazy" class="logo" alt="logo" />
                </div>
            </div>
        </transition>

        <router-view />
    </div>
</template>

<script>
import { detect } from "detect-browser"
const browser = detect()

export default {
    name: "App",
    data() {
        return {}
    },
    computed: {
        splashScreen() {
            return this.$store.getters.splashScreen
        }
    },
    methods: {},
    components: {},
    created() {
        if (browser && browser.name) document.getElementsByTagName("html")[0].classList.add(browser.name)
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
#app {
    width: 100%;
    height: 100%;

    .splash-screen {
        transition: all 0.5s;

        img {
            transition: all 0.3s;
        }
    }

    .fade-zoom-enter {
        opacity: 0;
        transform: translate(0, 30px);
    }
    .fade-zoom-leave-active {
        opacity: 0;

        img {
            transform: scale(0.5);
            opacity: 0;
        }
    }
}
</style>
