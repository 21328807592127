const ChatPage = () => import("../views/chat/Chat.vue")
const ChatAttachmentsPage = () => import("../views/chat/ChatAttachments.vue")
const ChatReportsPage = () => import("../views/chat/ChatReports.vue")
const ChatInstitutionalPage = () => import("../views/chat/ChatInstitutional.vue")
const ChatStatsPage = () => import("../views/chat/ChatStats.vue")

export default [
    {
        path: "/chat/rooms/:room?/:message?",
        name: "Stanze",
        component: ChatPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Chat / Stanze",
            tags: ["chat", "rooms"]
        }
    },
    {
        path: "/chat/attachments/:room?",
        name: "Allegati",
        component: ChatAttachmentsPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Chat / Allegati",
            tags: ["chat", "rooms", "attachments", "foto"]
        }
    },
    {
        path: "/chat/reports",
        name: "Segnalazioni",
        component: ChatReportsPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Chat / Segnalazioni",
            tags: ["chat", "rooms", "reports"]
        }
    },
    {
        path: "/chat/institutional",
        name: "Messaggi istituzionali",
        component: ChatInstitutionalPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Chat / Messaggi istituzionali",
            tags: ["chat", "rooms", "avvisi"]
        }
    },
    {
        path: "/chat/stats",
        name: "Statistiche",
        component: ChatStatsPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Chat / Statistiche",
            tags: ["chat", "statistiche", "grafico"]
        }
    }
]
