const UsersListPage = () => import("../views/users/UsersList.vue")
const UserPage = () => import("../views/users/User.vue")
const UsersExportPage = () => import("../views/users/Export.vue")

export default [
    {
        path: "/users",
        name: "Lista utenti",
        component: UsersListPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Utenti / Lista",
            tags: ["utenti", "users", "lista"]
        }
    },
    {
        path: "/users/:id",
        name: "Dettaglio utente",
        component: UserPage,
        meta: {
            auth: true,
            role: "all",
            searchable: false,
            title: "Dettaglio utente",
            tags: ["utenti", "users", "profilo", "info"]
        }
    },
    {
        path: "/users-export",
        name: "Export utenti",
        component: UsersExportPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Utenti / export",
            tags: ["utenti", "users", "lista"]
        }
    }
]
