/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|      CORE LIBS      |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
//import 'es6-promise/auto'
//import 'es7-shim'
//import "core-js"
import Vue from "vue"
import Element from "element-ui"
import locale from "element-ui/lib/locale/lang/it"
import vClickOutside from "v-click-outside"
import VueFloatLabel from "vue-float-label"
import Dayjs from "./plugins/Dayjs"
import Sugar from "./plugins/Sugar"
import _merge from "lodash/merge"

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS LIBS   |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import VueTruncate from "vue-truncate-filter"
import Fullscreen from "vue-fullscreen"
import VueResize from "vue-resize"
import "vue-resize/dist/vue-resize.css"
import Donut from "vue-css-donut-chart"
import "vue-css-donut-chart/dist/vcdonut.css"
import vFlagIcons from "v-flag-icons"
import "v-flag-icons/css/rounded-rectangle.min.css"
import Clipboard from "v-clipboard"
//import VueMasonry from "vue-masonry-css"
import VueImg from "v-img"
import VPureTooltip from "./directives/v-pure-tooltip"
import VueTextareaAutosize from "vue-textarea-autosize"
import "vue-swatches/dist/vue-swatches.css"
import Vue2TouchEvents from "vue2-touch-events"
import ImagePainter from "vue-image-painter"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import VueAnimXYZ from "@animxyz/vue"
import "@animxyz/core" // Import css here if you haven't elsewhere

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|     CORE ASSETS     |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
//import './assets/scss/element-variables.scss'
import "./assets/scss/global.scss"
import "flex.box"
import "animate.css"
import "@mdi/font/css/materialdesignicons.min.css"
import App from "./App.vue"
import router from "./router/"
import store from "./store"

Vue.config.productionTip = false
//Vue.config.performance = process.env.NODE_ENV !== "production"

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS COMPONENTS |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ */

if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    Sentry.init({
        Vue,
        release: `imamma-community-panel-frontend@${process.env.VUE_APP_VERSION}`,
        dsn: "https://229fcbe004294ad0b84e06d75f34e690@o915066.ingest.sentry.io/5857485",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // tracesSampleRate: 0.1
    })
}

Vue.use(ImagePainter)
Vue.use(Vue2TouchEvents)
Vue.use(VPureTooltip)
//Vue.use(VueMasonry)
Vue.use(VueTruncate)
Vue.use(VueResize)
Vue.use(Fullscreen)
Vue.use(Donut)
Vue.use(vFlagIcons)
Vue.use(Clipboard)
Vue.use(VueImg)
Vue.use(VueAnimXYZ)
Vue.use(VueTextareaAutosize)

/* ═ ═ ═ ═ ═ ═ ═ ═ *\
|  CORE COMPONENTS  |
\* ═ ═ ═ ═ ═ ═ ═ ═ */
Vue.use(Element, { locale: _merge(locale, { el: { datepicker: { year: "", week: "settimana" } } }) })
Vue.use(Dayjs, { locale: "it" })
Vue.use(Sugar)
Vue.use(vClickOutside)
Vue.use(VueFloatLabel)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
