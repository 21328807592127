import CryptoJS from "crypto-js"
const config = process.env

export default {
    getUrl: endpoint => `${config.VUE_APP_API_URL}/api/v${config.VUE_APP_API_VERSION}/${endpoint}`,

    getSecret: () => config.VUE_APP_CRYPT_KEY,
    // Encrypt the message using a generated key
    encryptAES(value, key) {
        return CryptoJS.AES.encrypt(value.toString(), key.toString()).toString()
    },
    // Decrypt the message using the generated key
    decryptAES(encrypted, key) {
        return CryptoJS.AES.decrypt(encrypted.toString(), key.toString()).toString(CryptoJS.enc.Utf8)
    },
    // Encode String to Base64
    encodeBase64(value) {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(value.toString()))
    },
    // Decode String from Base64 Enconding
    decodeBase64(encodedValue) {
        return CryptoJS.enc.Base64.parse(encodedValue.toString()).toString(CryptoJS.enc.Utf8)
    },
    // Encode String to MD5
    encodeMD5(value) {
        return CryptoJS.MD5(value.toString()).toString()
    },
    // Generate the random key
    generateKey() {
        return CryptoJS.lib.WordArray.random(16).toString()
    },

    // Transform File Instance in base64 string
    file2Base64: file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        }),

    isEnvDev() {
        return process.env.NODE_ENV === "development"
    },
    isEnvTest() {
        return process.env.NODE_ENV === "test"
    },
    isEnvProd() {
        return process.env.NODE_ENV === "production"
    }
}
