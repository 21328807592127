const ReviewsPublishedPage = () => import("../views/reviews/ReviewsPublished.vue")
const ReviewsDraftPage = () => import("../views/reviews/ReviewsDraft.vue")
const ReviewPage = () => import("../views/reviews/Review.vue")

export default [
    {
        path: "/reviews/published",
        name: "Lista Recensioni pubblicate",
        component: ReviewsPublishedPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Recensioni / Pubblicate",
            tags: ["reviews", "lista", "published"]
        }
    },
    {
        path: "/reviews/draft",
        name: "Lista Recensioni in bozza",
        component: ReviewsDraftPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Recensioni / Bozze",
            tags: ["reviews", "lista", "draft"]
        }
    },
    {
        path: "/reviews/:id?",
        name: "Recensione",
        component: ReviewPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Recensioni / Nuova",
            tags: ["review", "new"]
        }
    }
]
