const AnswersPage = () => import("../views/qna/AnswersPage.vue")
const QuestionsPage = () => import("../views/qna/QuestionsPage.vue")
const QuestionSinglePage = () => import("../views/qna/QuestionSinglePage.vue")
const QuestionAttachments = () => import("../views/qna/QuestionAttachments.vue")
const AnswerAttachments = () => import("../views/qna/AnswerAttachments.vue")

export default [
    {
        path: "/qna/questions/:id/:answer?",
        name: "Domanda",
        component: QuestionSinglePage,
        meta: {
            auth: true,
            role: "all",
            searchable: false,
            title: "Q&A / Domanda"
        }
    },
    {
        path: "/qna",
        redirect: "/qna/questions"
    },
    {
        path: "/qna/questions",
        name: "Lista domande",
        component: QuestionsPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Q&A / Lista domande",
            tags: ["question", "domanda", "domande"]
        }
    },
    {
        path: "/qna/answers",
        name: "Lista risposte",
        component: AnswersPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Q&A / Lista risposte",
            tags: ["answer", "risposta", "risposte"]
        }
    },
    {
        path: "/qna/question-attachments",
        name: "Lista allegati domande",
        component: QuestionAttachments,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Q&A / Lista allegati domande",
            tags: ["answer", "allegati", "allegato"]
        }
    },
    {
        path: "/qna/answer-attachments",
        name: "Lista allegati risposte",
        component: AnswerAttachments,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Q&A / Lista allegati risposte",
            tags: ["answer", "allegati", "allegato"]
        }
    }
]
