import { Http } from "vue-resource"
import utils from "../utils"

export default {
    /**
     * @param {Object} params - qna list pagination
     * @param {number} params.pagination - items per page
     * @param {number} params.page - page index
     * @param {number} params.user_id - user id
     * @param {string} params.category - qna category filter
     * @param {boolean} params.suspended - qna suspended filter
     * @param {boolean} params.attachments - qna attachments filter
     *
     * @return {void}
     */
    getAll(params) {
        return Http.get(utils.getUrl("qna"), {
            params
        })
    },
    getOne(id) {
        return Http.get(utils.getUrl(`qna/${id}`))
    },
    /**
     * @param {Object} params - qna comments list pagination
     * @param {number} params.pagination - items per page
     * @param {number} params.page - page index
     * @param {number} params.user_id - user id
     * @param {string} params.category - qna category filter
     * @param {boolean} params.suspended - qna suspended filter
     * @param {boolean} params.attachments - qna attachments filter
     * @param {number} id - qna id
     *
     * @return {void}
     */
    getComments(params, id) {
        return Http.get(utils.getUrl(`qna/${id}/comments`), {
            params
        })
    },
    delete(id) {
        return Http.delete(utils.getUrl(`qna/${id}`))
    },
    deleteComment(qnaId, commentId) {
        return Http.delete(utils.getUrl(`qna/${qnaId}/comments/${commentId}`))
    },
    restore(id) {
        return Http.put(utils.getUrl(`qna/${id}`))
    },
    restoreComment(qnaId, commentId) {
        return Http.put(utils.getUrl(`qna/${qnaId}/comments/${commentId}`))
    },
    /**
     *
     * @param {Object} comment comment to send
     * @param {String} comment.content comment message
     * @param {String} comment.image comment attachment
     * @param {number} qnaId - qna id
     */
    addComment(comment, qnaId) {
        return Http.post(utils.getUrl(`qna/${qnaId}/comments`), comment)
    }
}
