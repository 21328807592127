const ChannelTelegramPage = () => import("../views/channels/ChannelTelegram.vue")

export default [
    {
        path: "/channels/telegram",
        name: "Telegram",
        component: ChannelTelegramPage,
        meta: {
            auth: true,
            role: "all",
            searchable: true,
            title: "Canali / Messaggi Canale Telegram",
            tags: ["telegram", "channel", "bot"]
        }
    }
]
