import Vue from "vue"
import Router from "vue-router"

//views
const Main = () => import("../views/Main.vue")
const Dashboard = () => import("../views/Dashboard.vue")
const ConfigurationPage = () => import("../views/Configuration.vue")
const ActivityLogsPage = () => import("../views/logs/ActivityLogs.vue")
const ErrorLogsPage = () => import("../views/logs/ErrorLogs.vue")
const ProfilePage = () => import("../views/Profile.vue")

const Login = () => import("../views/authentication/Login.vue")
const NotFound = () => import("../views/NotFound.vue")

import ChatRouter from "./chat"
import UsersRouter from "./users"
import ReviewsRouter from "./reviews"
import SupportRouter from "./support"
import QnaRouter from "./qna"
import ChannelsRouter from "./channels"

import store from "../store"
import Const from "../const"
import api from "../api"

Vue.use(Router)

const logout = cb => {
    store.commit("setLogout")
    if (cb && typeof cb === "function") {
        cb()
    }
}

const router = new Router({
    mode: "history", // hash
    //base: '/sub-path/',
    routes: [
        {
            path: "/",
            component: Main,
            beforeEnter: (to, from, next) => {
                const token = to?.query?.token || ""
                const redirect_url = to?.fullPath || ""

                if (!store.getters.isLogged || !Const.roles.includes(store.getters.accountRole)) {
                    logout()
                    return next({
                        path: `/login?redirect_url=${redirect_url}&token=${token}`,
                        replace: true
                    })
                }

                api.auth
                    .checkSession()
                    .then(res => {
                        if (to.path === "/") {
                            return next({ path: "/dashboard", replace: true })
                        }

                        return next()
                    })
                    .catch(err => {
                        logout()
                        return next({
                            path: `/login?redirect_url=${redirect_url}&token=${token}`,
                            replace: true
                        })
                    })
            },
            children: [
                {
                    path: "/dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                    meta: {
                        auth: true,
                        role: "all",
                        searchable: true,
                        title: "Dashboard",
                        tags: ["bacheca", "resoconto"]
                    }
                },
                {
                    path: "/settings",
                    name: "Impostazioni",
                    component: ConfigurationPage,
                    meta: {
                        auth: true,
                        role: "admin",
                        searchable: true,
                        title: "Impostazioni",
                        tags: ["configurazioni", "settings", "telegram", "bot", "token"]
                    }
                },

                ...ChatRouter,
                ...UsersRouter,
                ...ReviewsRouter,
                ...SupportRouter,
                ...QnaRouter,
                ...ChannelsRouter,

                {
                    path: "/activity-logs",
                    name: "Activity",
                    component: ActivityLogsPage,
                    meta: {
                        auth: true,
                        role: ["admin", "readonly"],
                        searchable: true,
                        title: "Logs / Activity",
                        tags: ["logs"]
                    }
                },
                {
                    path: "/error-logs",
                    name: "Errors",
                    component: ErrorLogsPage,
                    meta: {
                        auth: true,
                        role: ["admin", "readonly"],
                        searchable: true,
                        title: "Logs / Errors",
                        tags: ["logs"]
                    }
                },

                {
                    path: "/profilo",
                    name: "Profilo",
                    component: ProfilePage,
                    meta: {
                        auth: true,
                        role: "all",
                        searchable: true,
                        title: "Profilo",
                        tags: ["info", "impostazioni"]
                    }
                }
            ]
        },

        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                auth: false,
                role: "all"
            }
        },
        {
            path: "/logout",
            meta: {
                auth: false,
                role: "all"
            },
            redirect: to => {
                logout()
                return "/login"
            }
        },
        {
            path: "*",
            name: "not-found",
            component: NotFound,
            meta: {
                auth: false,
                role: "all"
            }
        }
    ]
})

router.beforeResolve((to, from, next) => {
    const userRole = store.getters.accountRole
    const isLogged = store.getters.isLogged

    if (to && to.meta && to.meta.auth) {
        if (isLogged) {
            if (to.meta.role) {
                if (to.meta.role === userRole || to.meta.role.includes(userRole) || to.meta.role === "all") {
                    return next()
                } else {
                    logout()
                    return next({ path: "/login", replace: true })
                }
            } else {
                return next()
            }
        } else {
            logout()
            return next({ path: "/login", replace: true })
        }
    } else {
        if ((to.name === "login" || to.path === "/login") && isLogged) {
            return next({ path: "/", replace: true })
        } else {
            return next()
        }
    }
})

router.afterEach(() => {
    setTimeout(() => {
        store.commit("setSplashScreen", false)
    }, 500)
})

export default router
