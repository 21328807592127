const getters = {
    isAdmin(state, getters) {
        return getters.accountRole === "admin"
    },
    isSuperModerator(state, getters) {
        return getters.accountRole === "supermoderator"
    },
    isModerator(state, getters) {
        return getters.accountRole === "moderator"
    },
    isReadOnly(state, getters) {
        return getters.accountRole === "readonly"
    },
    can_SaveUserPDF(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_EditUser(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_SaveUser(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_DeleteUser(state, getters) {
        return getters.isAdmin
    },
    can_PublishReview(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_DeleteReviewDraft(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_ViewPregnancyDetails(state, getters) {
        return getters.isAdmin
    },
    can_ViewChildDetails(state, getters) {
        return getters.isAdmin
    },
    can_ManageThreadLabels(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_UpdateSupportSign(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_ViewReviewsCountWidget(state, getters) {
        return !getters.isReadOnly
    },
    can_PerformQnAActions(state, getters) {
        return !getters.isReadOnly
    },
    can_PerformUserActions(state, getters) {
        return !getters.isReadOnly
    },
    can_PerformRequestActions(state, getters) {
        return !getters.isReadOnly
    },
    can_PerformReportsActions(state, getters) {
        return !getters.isReadOnly
    },
    can_ReplyToRequest(state, getters) {
        return !getters.isReadOnly
    },
    can_ViewReviewsNav(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_ViewBotNav(state, getters) {
        return getters.isAdmin || getters.isSuperModerator
    },
    can_ViewLogsNav(state, getters) {
        return getters.isAdmin || getters.isReadOnly
    },
    can_ViewSettingsNav(state, getters) {
        return getters.isAdmin
    },
    can_ViewSupportTemplatesNav(state, getters) {
        return !getters.isReadOnly
    },
    can_ViewChatInstitutionalNav(state, getters) {
        return !getters.isReadOnly
    },
    can_DeleteChatMessage(state, getters) {
        return !getters.isReadOnly
    }
}

export default {
    getters
}
