import { Http } from "vue-resource"
import utils from "../utils"

export default {
    get(id) {
        return Http.get(utils.getUrl(`reviews${id ? "/" + id : ""}`))
    },
    getPublished() {
        return Http.get(utils.getUrl(`reviews/published`))
    },
    getDraft() {
        return Http.get(utils.getUrl(`reviews/draft`))
    },
    /**
     *
     * @param {string[]} order
     */
    setOrder(order) {
        return Http.post(utils.getUrl(`reviews/order`), { order })
    },
    save(review) {
        return Http.post(utils.getUrl(`reviews`), review)
    },
    haveDraft(id) {
        return Http.get(utils.getUrl(`reviews/${id}/have-draft`))
    },
    havePublished(id) {
        return Http.get(utils.getUrl(`reviews/${id}/have-published`))
    },
    deleteDraft(id) {
        return Http.delete(utils.getUrl(`reviews/${id}`))
    },
    /**
     *
     * @param {Object} meta meta to send
     * @param {String} meta._id mongo id
     * @param {number} meta.id mysql id
     * @param {number} meta.author_username user username
     * @param {number} meta.author_id user id
     * @param {String} meta.category review category
     * @param {String} meta.genre review genre
     * @param {number | string} meta.index review index
     * @param {number | string} meta.index_to review index_to
     * @param {String} meta.color review color
     * @param {boolean} meta.featured review featured
     */
    saveReviewMeta(meta) {
        return Http.post(utils.getUrl(`reviews/meta`), meta)
    }
}
