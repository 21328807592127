import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ encodingType: "aes", isCompression: false })
import utils from "@/utils"
import api from "@/api"
import acl from "./modules/acl"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
    state: {
        layout: {
            footer: "below", //above, below, false
            roundedCorners: false, //true, false
            viewAnimation: "fade-top" // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
        },
        account: {
            email: "",
            mysqlId: "",
            firstname: "",
            lastname: "",
            picture: "",
            username: "",
            role: "",
            token: ""
        },
        accountEncrypted: "",
        logged: false,
        splashScreen: true,
        penaltyReasons: []
    },
    mutations: {
        setLayout(state, payload) {
            if (payload && payload.footer !== undefined) state.layout.footer = payload.footer

            if (payload && payload.roundedCorners !== undefined) state.layout.roundedCorners = payload.roundedCorners

            if (payload && payload.viewAnimation !== undefined) state.layout.viewAnimation = payload.viewAnimation
        },
        setLogin(state, payload) {
            state.account = {
                email: payload.email,
                mysqlId: payload.mysqlId,
                firstname: payload.firstname,
                lastname: payload.lastname,
                picture: payload.picture,
                username: payload.username,
                role: payload.role,
                token: payload.token
            }
            state.accountEncrypted = utils.encryptAES(JSON.stringify(state.account), utils.getSecret())
            state.logged = true
        },
        setLogout(state, payload) {
            // state.layout.navPos = null
            // state.layout.toolbar = null
            state.account = {
                email: "",
                mysqlId: "",
                firstname: "",
                lastname: "",
                picture: "",
                username: "",
                role: "",
                token: ""
            }
            state.accountEncrypted = ""
            state.logged = false
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload
        },
        setPenaltyReasons(state, payload) {
            state.penaltyReasons = payload
        }
    },
    actions: {
        getPenaltyReasons(context, payload) {
            return api.common
                .getPenaltyReasons()
                .then(res => {
                    if (res.status === 200 && res.body) {
                        context.commit("setPenaltyReasons", res.body)
                    }
                    return res
                })
                .catch(err => {
                    throw err
                })
        }
    },
    getters: {
        account(state, getters) {
            return state.account
        },
        accountEncrypted(state, getters) {
            return state.accountEncrypted
        },
        accountName(state, getters) {
            return `${state.account.firstname} ${state.account.lastname}`
        },
        accountFirstname(state, getters) {
            return state.account.firstname
        },
        accountLastname(state, getters) {
            return state.account.lastname
        },
        accountRole(state, getters) {
            return state.account.role
        },
        accountEmail(state, getters) {
            return state.account.email
        },
        accountUsername(state, getters) {
            return state.account.username
        },
        accountId(state, getters) {
            return state.account.mysqlId
        },
        accountPicture(state, getters) {
            return state.account.picture
        },
        layout(state, getters) {
            return state.layout
        },
        footer(state, getters) {
            return state.layout.footer
        },
        roundedCorners(state, getters) {
            return state.layout.roundedCorners
        },
        viewAnimation(state, getters) {
            return state.layout.viewAnimation
        },
        isLogged(state, getters) {
            return state.logged
        },
        penaltyReasons(state, getters) {
            return state.penaltyReasons
        },
        splashScreen(state, getters) {
            return state.splashScreen
        }
    },
    modules: {
        acl
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            paths: ["layout", "account", "logged", "accountEncrypted", "penaltyReasons"]
        })
    ],
    strict: debug
})
