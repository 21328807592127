import { Http } from "vue-resource"

const config = process.env
import store from "../store"

import auth from "./auth"
import common from "./common"
import qna from "./qna"
import chat from "./chat"
import users from "./users"
import reviews from "./reviews"
import emails from "./emails"
import bot from "./bot"

Http.options.credentials = true
Http.options.xhr = { withCredentials: true }

Http.interceptors.push(request => {
    request.headers.set("X-DATA", store.getters.accountEncrypted)
    // return response callback
    return response => {
        if (response.status === 401 || response.status === 412 || response.status === 409) {
            if (window.location.pathname.indexOf("login") === -1) {
                window.location.href = "/logout"
            }
        }
    }
})

//let DOMAIN_URL = "http://localhost:3012"
//if (process.env.NODE_ENV !== "development") DOMAIN_URL = location.origin
//DOMAIN_URL = ''
//const BASE_URL = DOMAIN_URL + "/api/v1"

const DOMAIN_URL = config.VUE_APP_API_URL
const API_VERSION = config.VUE_APP_API_VERSION
const BASE_URL = DOMAIN_URL + "/api/v" + API_VERSION

export default {
    getBaseUrl() {
        return BASE_URL
    },
    getDomainUrl() {
        return DOMAIN_URL
    },

    auth,
    common,
    qna,
    chat,
    users,
    reviews,
    emails,
    bot
}
